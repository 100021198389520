<template>
    <div class="contents_rule">
        <div v-html="settingDate.config.shareholder_agreement"></div>
    </div>
</template>
<script>
import { getSetting } from "@/api/common";
    export default{
        data(){
            return{
                settingDate:{
                    config:{

                    }
                },
                strings:'23123'
            }
        },
        created(){
            this.getConfig()
        },
        methods:{
             getConfig() {
                getSetting().then(res=>{
                    console.log(res.status)
                    if(res.code==0){
                        this.settingDate = res.data;
                        this.settingDate.config.shareholder_agreement = this.settingDate.config.shareholder_agreement.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
                        this.$forceUpdate()
                    }
                })
            }
        }
    }
</script>
<style>
    .contents_rule{
        padding: 40px;box-sizing: border-box
    }

</style>